import logo from './logo.svg';
import './App.css';
import { useState, useRef } from 'react';

function App() {
  const [started, setStarted] = useState(false);
  const audioRef = useRef(null);  // Create a ref for the audio element

  const startApp = () => {
    setStarted(true);
    audioRef.current.play();  // Play the audio when the app starts
  };

  return (
    <div className="App">
      {started ? 
      <>
        <div className="text">
          <img className="logo" src="logo.jpg" alt="Logo" />
          <div>$BLADE</div>
          <div className="smalltext">🧛 240 vampires slayed</div>
          <a href="https://birdeye.so/?chain=solana" target="_blank" rel="noopener noreferrer" className="joinButton">JOIN THE BATTLE</a>
        </div>
        <header className="App-header">
        </header>
      </>
      :
      <div className="getStarted">
      <div className="joinButton" onClick={startApp}>Slay vampires</div>
      </div>
      }

      {/* Audio element */}
      <audio ref={audioRef} src="song.mp3" type="audio/mp3" preload="auto">
        Your browser does not support the audio tag.
      </audio>
    </div>
  );
}

export default App;
